p {
  margin: 0px !important;
}

.h-margin-reset {
  margin-right: 0px;
  margin-left: 0px;
}

.v-margin-reset {
  margin-top: 0px;
  margin-bottom: 0px;
}

.fans-count {
  padding: 1px;
  font-size: 20px;
}

.dash-card {
  background-color: #eeeef1;
  padding: 20px;
  border-radius: 10px;
}

.v-padding-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.v-padding-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.v-top-10 {
  margin-top: 10px;
}
.v-top-5 {
  margin-top: 5px;
}

.title-row {
  margin-bottom: 10px;
}

.card-title {
  margin-top: 10px;
  margin-bottom: 20px;
}

.fans-row {
  margin-top: 20px;
  margin-left: 10px;
}

.button-snagm {
  background-color: #2a5ca6;
  margin-bottom: 5px;
  margin-right: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.campaign-row {
  padding-left: 10px;
  padding-right: 10px;
}

.button-campaign {
  background-color: #2a5ca6;
  margin-top: 20px;
  margin-bottom: 5px;
}

.button-warning {
  background-color: red;
  margin-top: 20px;
  margin-bottom: 5px;
}

.snagm-color {
  background-color: #2a5ca6;
}

.snagm-font {
  color: #2a5ca6;
}

.button-margins {
  margin-top: 20px;
  margin-bottom: 10px;
}

.side-margins {
  margin-left: 10px;
  margin-right: 0px;
}

.active-icon-style {
  color: #248928;
}

.inactive-icon-style {
  color: #888888;
}

.review-icon-style {
  color: #2a5ca6;
}

.rejected-icon-style {
  color: #bc0b0b;
}

.right-justify {
  justify-content: flex-end;
}

.right-align {
  display: flex;
  align-items: flex-end;
}

.text-align-right {
  text-align: right;
}

.beacon-yellow {
  background-color: yellow;
}

.beacon-gray {
  background-color: gray;
}

.title-small-row {
  margin-bottom: 15px;
}

.redeemed-row {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: large;
}

.card-max {
  max-width: 500px;
}

.coupon-title {
  background-color: #464d54;
  color: #eeeef1;
  padding: 5px;
  padding-top: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-top: 10px;
  margin-right: 0px;
  margin-left: 0px;
  font-weight: bold;
  font-size: larger;
}

.coupon-subtitle {
  background-color: #464d54;
  color: #eeeef1;
  padding-bottom: 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-right: 0px;
  margin-left: 0px;
}

.coupon-terms {
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #bbbec6;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: small;
}

.info-circle {
  color: #888888;
}

.padded-top {
  margin-top: 20px;
}

.padded-row {
  padding-left: 10px;
  padding-right: 10px;
}

.padded-row-top {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}

.order-row-top {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 1px;
}

.button-publish {
  background-color: #248928;
  border-color: #248928;
  margin-top: 5px;
}

.button-publish:hover {
  background-color: #888;
  border-color: #888;
  margin-top: 5px;
}

.details-font {
  font-size: small;
}

.details-window {
  margin-right: 0px;
  margin-left: 0px;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  padding: 5px;
}

.history-window {
  margin-right: 0px;
  margin-left: 0px;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.history-title {
  background-color: #464d54;
  color: #eeeef1;
  padding: 5px;
  padding-top: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-right: 0px;
  margin-left: 0px;
  font-weight: bold;
  font-size: large;
}

.big-top {
  margin-top: 20px;
}

.chart-top {
  margin-top: 15px;
}

.med-top {
  margin-top: 10px;
}

.small-bottom {
  margin-bottom: 5px;
}

.big-bottom {
  margin-bottom: 20px;
}

.button-next {
  background-color: #2a5ca6;
  margin-top: 10px;
  margin-bottom: 5px;
}

.grow-1 {
  display: flex;
  flex-grow: 1;
}

.grow-3 {
  display: flex;
  flex-grow: 3;
}

.grow-5 {
  display: flex;
  flex-grow: 5;
}

.align-bottom {
  display: flex;
  align-self: center;
}

.overlay-center {
  display: flex;
  padding-top: 90px;
  flex-direction: column;
  justify-content: center;
}

.no-padding {
  padding: 0px;
}

.no-margin {
  margin: 0px;
}

.overlay-title {
  font-size: 30px;
  font-weight: bolder;
  text-align: center;
}

.overlay-tagline {
  font-size: 20px;
  font-weight: normal;
  text-align: center;
}

.margin-sides {
  margin-left: 10px;
  margin-right: 10px;
}

.margin-sides-sm {
  margin-left: 5px;
  margin-right: 5px;
}

.coupon-list {
  background-color: #fff;
  border-radius: 5px;
  padding-left: 10px;
  padding-top: 5px;
  margin-left: 10px;
}

.bigger-text {
  font-size: larger;
}

.smaller-text {
  font-size: smaller;
}

.side-padding {
  padding-left: 10px;
  padding-right: 10px;
}

.dots-class {
  all: revert;
}

.padding-5 {
  padding: 5px;
}

.link-style {
  color: #2a5ca6;
  cursor: pointer;
  font-size: small;
}

.scan-row {
  margin-top: 20px;
}

.scan-div {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.search-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.search-column1 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.search-button {
  background-color: #2a5ca6;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.search-text {
  font-size: 18px;
}

.alert-box {
  margin-bottom: 10px;
  text-align: center;
  padding: 10px;
  margin-top: 0px;
}

.button-create {
  background-color: #2a5ca6;
  margin-bottom: 5px;
}

.login-button {
  background-color: #2a5ca6;
  outline-color: #2a5ca6;
  margin-bottom: 5px;
  margin-top: 0px;
}

.client-title {
  color: #2a5ca6;
  font-size: 24px;
  font-weight: bolder;
}

.results-row {
  padding-left: 40px;
}

.results-text {
  font-size: 18px;
  cursor: pointer;
}

.inline-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.center-text {
  text-align: center;
}

.delete-icon {
  color: #888;
  cursor: pointer;
  padding: 0 !important;
  font-size: 20px;
  margin-top: -5px;
}

.delete-icon:hover {
  color: #bc0b0b;
}

.delete-column {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}
